//to handle state
const state = {
    catering_detail: [],
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadAddCateringDetail(context,letter) {
        let validate = true;
        let detail = context.state.catering_detail;
        for (let index = 0; index < detail.length; index++) {
            if (detail[index].id_letter == letter.id_letter) {
                detail[index].quantity = parseFloat(detail[index].quantity) + parseFloat(letter.quantity);
                detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
                validate = false;
                break;
            }
        }
        if (validate) {
            context.commit('mAddCateringDetail',letter);
        }
        
    },

    mLoadEditCateringDetail(context,index){
        let detail = context.state.catering_detail;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
    },

    mLoadDeleteCateringDetail(context,index){
        context.commit('mDeleteCateringDetail',index);
    },

    mLoadResetCateringDetail(context){
        context.commit('mResertCateringDetail');
    },


}

//to handle mutations
const mutations = {

    mAddCateringDetail(state, detail) {
        state.catering_detail.push(detail);
    },

    mDeleteCateringDetail(state, index) {
        state.catering_detail.splice(index,1);
    },
    mResertCateringDetail(state) {
        state.catering_detail = [];
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}