//to handle state
const state = {
    new_order_detail: [],
    order_detail: [],
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadNewAddOrderDetail(context,letter) {
        context.commit('mNewAddOrderDetail',letter);
    },

    mLoadNewEditOrderDetail(context,index){
        let detail = context.state.new_order_detail;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
    },

    mLoadNewDeleteOrderDetail(context,index){
        context.commit('mNewDeleteOrderDetail',index);
    },

    mLoadNewResetOrderDetail(context){
        context.commit('mNewResertOrderDetail');
    },
    mLoadNewAddOrderDetailObservation(context,data) {
        context.commit('mNewAddOrderDetailObservation',data);
    },











    mLoadAddOrderDetail(context,letter) {
        context.commit('mAddOrderDetail',letter);
    },

    mLoadEditOrderDetail(context,index){
        let detail = context.state.order_detail;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
    },

    mLoadDeleteOrderDetail(context,index){
        context.commit('mDeleteOrderDetail',index);
    },

    mLoadResetOrderDetail(context){
        context.commit('mResertOrderDetail');
    },

}

//to handle mutations
const mutations = {

    mNewAddOrderDetail(state, detail) {
        state.new_order_detail.push(detail);
    },
    mNewDeleteOrderDetail(state, index) {
        state.new_order_detail.splice(index,1);
    },
    mNewResertOrderDetail(state) {
        state.new_order_detail = [];
    },
    mNewAddOrderDetailObservation(state, detail) {
        state.new_order_detail[detail.index].observations.push(detail.name);
    },
    mNewDeleteOrderDetailObservation(state, detail) {
        state.new_order_detail[detail.index].observations.splice(detail.index_observation);
    },






    mAddOrderDetail(state, detail) {
        state.order_detail.push(detail);
    },

    mDeleteOrderDetail(state, index) {
        state.order_detail.splice(index,1);
    },
    mResertOrderDetail(state) {
        state.order_detail = [];
    },


}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}